import React, { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import useAnimationFrame from "../hooks/useAnimationFrame";
import Button from "./Button";
import { LogoMark, LogoName } from "./Logo";
import CheckoutModal from "./CheckoutModal";

const DropdownMenu = styled.div`
  position: relative;
  display: inline-block;

  &:hover .menu-links {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 120px;
  }
`;

const MenuLinks = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  padding: 10px;
  border-radius: 10%;
  font-size: 16px;
  font-weight: 600;
  background-color: #f0eeeb;
`;

const Navigation = () => {
  const modalRef = useRef();

  const handlePopup = () => {
    modalRef.current.showModal();
  };
  const [currentScrollPos, setCurrentScrollPos] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [navClassName, setNavClassName] = useState("maximized");

  const navTrigger = 50;

  useAnimationFrame((deltaTime) => {
    setCurrentScrollPos((currentScrollPos) => window.pageYOffset);
  });

  useLayoutEffect(() => {
    if (
      prevScrollPos < currentScrollPos &&
      currentScrollPos - prevScrollPos > navTrigger
    ) {
      setPrevScrollPos(currentScrollPos);
      setNavClassName("minimized");
    } else if (
      currentScrollPos < prevScrollPos &&
      prevScrollPos - currentScrollPos > navTrigger
    ) {
      setPrevScrollPos(currentScrollPos);
      setNavClassName("minimized");
    } else if (currentScrollPos < 100) {
      setNavClassName("maximized");
    }
  }, [currentScrollPos, prevScrollPos]);

  return (
    <StyledNav className={`is-${navClassName}`}>
      <div>
        <a href="/#about">About</a>
        <a href="/#highlights">Event</a>
        <a href="/#cause">Cause</a>
        <a href="https://buy.stripe.com/8wMaHEbf74rF6je5kk">Donate</a>
        {/* <Link to="/2022">
          Event<span> Ball</span>
        </Link> */}
        {/* <DropdownMenu>
          <a href="#">
            Past <span>Events</span>
          </a>
          <MenuLinks className="menu-links">
            <Link to="/2022" style={{ marginRight: 0 }}>2022 Events</Link>
            <Link to="/2023" style={{ marginRight: 0 }}>2023 Events</Link>
          </MenuLinks>
        </DropdownMenu> */}
      </div>
      <StyledLogo as={Link} to="/">
        <em>
          <LogoMark />
        </em>
        <span>
          <LogoName />
        </span>
      </StyledLogo>
      <div>
        <time>
          JUNE 27, 2024 <span>| Toronto</span>
        </time>
        {/* <Button as="a" href="/#tickets">
          Tickets
        </Button> */}
      </div>
    </StyledNav>
  );
};

export default Navigation;

const StyledLogo = styled.a`
  position: relative;
  display: grid;
  grid-template: auto auto / 1fr 0.75fr 1fr;
  max-width: 15em;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  transform-origin: top center;
  justify-self: center;
  align-self: start;
  grid-column: 2;
  grid-row: 1;
  em {
    grid-column: 2;
    grid-row: 1;
  }
  span {
    grid-column: 1 / span 3;
    grid-row: 2;
    transition: opacity 0.3s ease;
  }
  time {
    text-align: center;
    grid-column: 1 / span 3;
    grid-row: 2;
    transition: opacity 0.3s ease;
  }
  .is-minimized & {
    transform: translate3d(0, 5.25em, 0) scale(0.7);
    span {
      opacity: 0;
    }
    @media (max-width: 70em) {
      transform: translate3d(0, 3.5em, 0) scale(0.6);
    }
  }
`;

const StyledNav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 100;
  max-width: var(--max-width);
  margin: 0 auto;
  display: grid;
  grid-template: 1fr / 1fr 18em 1fr;
  padding: 1em var(--gutter);
  height: var(--nav-height);

  transition: transform 0.5s ease-in-out;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
    background-color: var(--color-light);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    border-bottom: 1px solid var(--color-border);
    z-index: -1;
  }
  &.is-minimized {
    transform: translate3d(0, -5.5em, 0);
    &::before {
      opacity: 1;
    }
  }

  > div {
    position: relative;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    &:last-of-type {
      justify-self: end;
    }
    a,
    time {
      font-size: 0.875em;
      font-weight: 600;
      text-transform: uppercase;
      display: inline-block;
      &:not(:last-child) {
        margin-right: 1.5em;
      }
    }
  }
  &.is-minimized {
    > div {
      opacity: 1;
      transform: translate3d(0, 2.75em, 0);
    }
  }

  @media (max-width: 70em) {
    &.is-minimized {
      transform: translate3d(0, -3.75em, 0);
      &::before {
        opacity: 1;
      }
    }
    &.is-minimized {
      > div {
        opacity: 1;
        transform: translate3d(0, 0.625em, 0);
        &:first-of-type {
          transform: translate3d(0, -0.5em, 0);
        }
      }
    }
    > div:first-of-type {
      grid-row: 2;
      grid-column: 1 / span 3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid var(--color-border);
      padding-top: 0.5em;
    }
    > div:last-of-type {
      position: absolute;
      z-index: -1;
      height: 3em;
      right: 0;
      bottom: 3.375em;
      left: 0;
      padding: 0 var(--gutter);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media (max-width: 40em) {
    time span,
    div:first-child a span {
      display: none;
    }
  }
`;
