import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import styled from "styled-components";
import Button from "../components/Button";
import NumberSpinner from "../components/NumberSpinner";
import Icons from "./Icon";

const CheckoutModal = forwardRef((props, ref) => {
  const [showModal, setShowModal] = useState(false);

  const product = {
    title: "Full Pass",
    price: "$300",
    perks: [
      "1 night cruise on the Yankee Lady",
      "Open bar, signature cocktails",
      "Passed hors d’oeuvres",
      "Mini-casino",
      "Silent Auction",
    ],
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useImperativeHandle(ref, () => ({
    showModal: () => handleOpen(),
    hideModal: () => handleClose(),
  }));

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  if (showModal) {
    return (
      <StyledModal onClick={handleClose}>
        <StyledModalContent onClick={(e) => e.stopPropagation()}>
          <h1>Checkout</h1>
          <h2>{product.title}</h2>
          <p>{product.price}</p>
          <ul>
            {product.perks.map((perk, index) => (
              <li key={`perk-${index}`}>{perk}</li>
            ))}
          </ul>
          <br></br>
          <form action="/api/checkout_sessions" method="POST">
            <input type="hidden" name="product" defaultValue={product.title} />
            <input
              type="hidden"
              name="ticketsSold"
              defaultValue={props.tickets}
            />
            {props.tickets < 225 ? (
              <>
                <label htmlFor="tickets">Number of Tickets</label>
                <NumberSpinner inventory={225 - props.tickets} />
                <br></br>
                <Button type="submit">Checkout</Button>
              </>
            ) : (
              <h3>Sorry, we're sold out!</h3>
            )}
            <StyledCloseButton type="button" onClick={handleClose}>
              <Icons icon="close" />
            </StyledCloseButton>
          </form>
        </StyledModalContent>
      </StyledModal>
    );
  }

  return null;
});

export default CheckoutModal;

const StyledCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  font-size: 1.5em;
  border-radius: 0 0.15em 0 0;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--color-highlight);
    color: var(--color-light);
    svg {
      fill: var(--color-light);
    }
  }
  svg {
    fill: var(--color-highlight);
  }
`;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(147, 126, 92);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const StyledModalContent = styled.div`
  position: relative;
  text-align: center;
  display: grid;
  grid-template: auto / 1fr;
  background-color: #f0eeeb;
  padding: 3em 1em;
  border-radius: 0.25em;
  max-width: 30em;
  width: 100%;
  h1 {
    margin: 0 0 0.5em;
    font-size: 1.5em;
  }
  form {
    display: grid;
    grid-gap: 1em;
    label {
      font-size: 1em;
    }
    input {
      padding: 0.5em;
      font-size: 1.125em;
    }
  }
  h2 {
    text-transform: uppercase;
    font-size: 1.125em;
    font-weight: 600;
    margin: 0 0 0.75em;
    line-height: 1;
  }
  p {
    font-size: 1.5em;
    font-family: var(--font-serif);
    margin: 0;
    letter-spacing: -0.05em;
    @media (min-width: 48em) {
      font-size: 1.75em;
    }
    @media (min-width: 60em) {
      font-size: 2.5em;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 1em 0 1.5em;
    color: var(--color-medium);
    display: grid;
    grid-template: auto / 1fr;
    grid-gap: 0.5em;
    @media (min-width: 48em) {
      li {
        font-size: 1.125em;
      }
    }
  }
  a {
    position: relative;
    display: inline-block;
    background-color: var(--color-highlight);
    color: var(--color-light);
    padding: 0.875em 2em 0.8375em;
    line-height: 1;
    font-size: 1.125em;
    font-weight: 600;
    justify-self: center;
    margin-top: auto;
  }
  button {
    justify-self: center;
    width: auto;
    margin-top: auto;
  }
`;
