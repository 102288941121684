import styled, { css } from "styled-components"

const Button = styled.button`
  position: relative;
  display: inline-block;
  background-color: var(--color-highlight);
  background-image: var(--color-gradient);
  color: var(--color-light);
  font-size: 1em;

  font-family: var(--font-sans);
  font-weight: 600;
  border: 0;
  line-height: 1.5;
  margin: 0;
  user-select: none;
  line-height: 2.65;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 0 1.25em;
  height: 2.5em;
  border: 0;
  border-radius: 0.25em;
  white-space: nowrap;
  transition: opacity 0.3s ease;
  box-shadow: 0 0.125em 0.375em -0.125em rgba(0,0,0,0.2);
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  ${props => props.outline && css`
    background-color: transparent;
    color: var(--color-text);
    box-shadow: inset 0 0 0 1px currentColor;
    line-height: 2.7;
  `}

  .can-hover &:hover,
  &:active {
    opacity: 0.5;
  }
  ${props => props.theme === 'light' && css`
      background-color: var(--color-background-light);
      color: var(--color-text-dark);
  `}
  ${props => props.size === 'small' && css`
    padding: 0 0.8125em 0 0.875em;
    height: 2.5em;
    line-height: 2.375;
    font-size: 0.8125em;
  `}
  svg {
      display: inline-block;
      vertical-align: middle;
      height: 1.5em;
      margin-right: 0.625em;
      margin-top: -0.25em;
      path {
        fill: currentColor;
      }
  }
  svg:first-child {
      margin-left:-0.25em;
  }
  span {
      display: inline-block;
      vertical-align: middle;
  }
`

export default Button