import React from "react";
import styled from "styled-components";
import Icons from "./Icon";

const NumberSpinner = (props) => {
  const [value, setValue] = React.useState(1);
  const inventory = props.inventory;

  const handleIncrement = () => {
    if (value < inventory) {
      setValue(value + 1);
    } else {
      setValue(inventory);
    }
  };

  const handleDecrement = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };

  const handleChange = (e) => {
    if (e.target.value > 0) {
      if (e.target.value > inventory) {
        setValue(inventory);
      } else {
        setValue(parseInt(e.target.value));
      }
    } else {
      setValue(0);
    }
  };

  return (
    <StyledNumberSpinner>
      <button type="button" onClick={handleDecrement}>
        <Icons icon="minus" />
      </button>
      <input
        type="text"
        name="tickets"
        min="0"
        max="10"
        value={value}
        onChange={handleChange}
      />
      <button type="button" onClick={handleIncrement}>
        <Icons icon="plus" />
      </button>
    </StyledNumberSpinner>
  );
};

export default NumberSpinner;

const StyledNumberSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-border);
  border-radius: 0.25em;
  max-width: 220px;
  margin: 0 auto;
  button {
    padding: 0.5em 1em;
    border: 0;
    background: transparent;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1;
    cursor: pointer;
    &:hover {
      background-color: var(--color-highlight);
      color: var(--color-light);
      svg {
        fill: var(--color-light);
      }
    }
  }
  input {
    width: 4em;
    height: 2em;
    border: 0;
    color: var(--color-highlight);
    background: transparent;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1;
    text-align: center;
  }
`;
