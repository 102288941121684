import React, { useEffect } from "react";
import "../fonts/fonts.css";
import "./styles/variables.scss";
import "./styles/reset.scss";
import styled from "styled-components";
import Navigation from "./Navigation";
import Footer from "./Footer";

function registerTouchables() {
  const touchables = document.querySelectorAll(
    "button, button, a[href], label"
  );
  let touchablesLength = touchables.length;

  while (touchablesLength--) {
    touchables[touchablesLength].addEventListener("touchstart", () => {}, {
      passive: true,
    });
    touchables[touchablesLength].addEventListener("touchend", () => {}, {
      passive: true,
    });
  }
}

function adjustOutsideLinks() {
  const anchors = document.querySelectorAll("a[href]");
  let anchorsLength = anchors.length;

  while (anchorsLength--) {
    if (anchors[anchorsLength].hostname !== window.location.hostname) {
      anchors[anchorsLength].target = "_blank";
      anchors[anchorsLength].rel = "noopener noreferrer";
    }
  }
}
const Layout = ({ children }) => {
  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add(`js`);

    registerTouchables();
    adjustOutsideLinks();

    const canHover = !matchMedia(`(hover: none)`).matches;
    if (canHover) {
      document.getElementsByTagName("html")[0].classList.add(`can-hover`);
    }
  });

  return (
    <>
      <Navigation />
      <StyledContent>
        {children}
        <Footer />
      </StyledContent>
    </>
  );
};

export default Layout;

export const Head = () => (
  <>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, viewport-fit=cover"
    />
  </>
);

const StyledContent = styled.div`
  margin: calc(-1 * var(--nav-height)) auto 0;
  padding: 0 var(--gutter);
  max-width: var(--max-width);
`;
