import React from "react";
import styled from "styled-components";

const Icons = ({ size = undefined, icon = undefined }) => (
  <>
    {icon === "close" && (
      <StyledIcons
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        size={size}
      >
        <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
      </StyledIcons>
    )}
    {icon === "plus" && (
      <StyledIcons
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        size={size}
      >
        <path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z" />
      </StyledIcons>
    )}
    {icon === "minus" && (
      <StyledIcons
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        size={size}
      >
        <path d="M0 12v1h23v-1h-23z" />
      </StyledIcons>
    )}
  </>
);

export default Icons;

const StyledIcons = styled.svg`
  position: relative;
  display: block;
  margin: auto;
  vertical-align: middle;
  height: ${(props) => (props.size ? `${props.size}rem` : "1em")};
  width: ${(props) => (props.size ? `${props.size}rem` : "1em")};
  fill: var(--color-highlight);
  polygon {
    fill: currentColor;
  }
  &:hover {
    fill: var(--color-light);
  }
`;
