import React from "react";
import styled from "styled-components";
import { Logo } from "./Logo";

const Footer = () => (
  <StyledFooter>
    <span>
      <Logo />
    </span>
    <div>
      <span>&copy; 2024 Ocean Ball Foundation</span>
      <a href="mailto:info@oceanball.org" title="Email us">Contact us</a>
    </div>
  </StyledFooter>
);

export default Footer;

const StyledFooter = styled.footer`
  position: relative;
  padding: 3em 0;
  background-color: var(--color-dark);
  display: grid;
  grid-template: auto / 1fr;
  place-items: center;
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    z-index: -1;
    background-color: inherit;
    box-shadow: inset 0 1px 0 var(--color-border);
  }
  @media ( min-width: 60em ) {
    padding: 6em 0 2em;
  }
  > span {
    display: block;
    width: 100%;
    width: 60%;
    max-width: 18em;
  }
  > div {
    position: relative;
    padding: 2em 0 0;
    margin-top: 3em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      margin-left: -50vw;
      border-top: 1px solid var(--color-border);
    }
    > span,
    > a {
      font-size: 0.6875em;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.05em;
      @media ( min-width: 48em ) {
        font-size: 0.75em;
      }
    }
  }
`;